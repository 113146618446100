<template>
  <div id="error_404" :style="image_404">
    <span class="error_404_text"> 404<br />Not found </span>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      image_404: {
        backgroundImage: `url('${require("@/assets/images/modal/404_error.webp")}')`,
        backgroundRepeat: "no-repeat",
      },
    };
  },
};
</script>

<style scoped>
#error_404 {
  width: 300px;
  height: 300px;
  margin: 0 0 0 50px;
  position: relative;
  right: -1rem;
}

.error_404_text {
  font-family: "Lucida Console", "Courier New", monospace;
  text-align: center;
  position: fixed;
  padding: 0 0 0 -20px;
  margin: 4em 0 0 -2em;
  font-size: 1.875em;
}
</style>
